<template>
    <div class="staff">
        <intro title="Our Staff" :content="paragraphs" id="intro"/>       
        <div class="pgcontent">
            <router-link to="/Staff"><h2><i class="bi-arrow-left"></i> Back to our staff</h2></router-link>
            <div v-for="staff in Dentists['Dentists']" :key="staff.name">
                <div class="staff-info" v-if="staff.name.includes($route.params.dentist)">
                    <staff-card :staff="staff" :cv="false"></staff-card>
                    <div class="cv-cards">
                        <div class="cv-card">
                            <h4>Education</h4>
                            <p v-for="title in staff.titles" :key="title">{{title}}</p>
                        </div>
                        <div class="cv-card">
                            <h4>Experience</h4>
                            <p>{{staff.description}}</p>
                        </div>
                        <div v-if="staff.gtkn" class="cv-card">
                            <h4>Get to know {{staff.name.split(' ')[1]}}</h4>
                            <p>{{staff.gtkn}}</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
              
    </div>
</template>
<style scoped>
    .pgcontent{
        display: flex;
        flex-wrap: wrap;
        background-color: var(--primary-variant);
        color: var(--font-dark);
        padding: 0 2rem;
    }
    .cv-card{
        margin-top: 1rem;
        padding-bottom: .5rem;
        border-top: 1px solid var(--secondary-variant);
    }
    .cv-card > h4{
        margin: .5rem 0;
    }
    .cv-card > p{
        margin: 0;
    }
    a{
        text-decoration: none;
        color: var(--primary);
        margin-bottom: 1rem;
    }
    h2{
        margin: 1rem 0 0;
    }
    @media screen and (min-width: 768px){
        .cv-card{
            margin: 1rem 5rem;
        }
        a{
            padding-left: 4rem;
        }
    }
    @media screen and (min-width: 1024px)
    {
        a>h2{
            padding-left: 0;
            margin: 2rem 0 0 0;
        }
        .staff-info{
            display: flex;
            flex-wrap: wrap;          
            padding: 0 calc(var(--padding1) - 2rem);  
        }  
        .staff-card{
            height: fit-content;
        }
        .cv-cards{
            width: 65%;
        }
        .cv-card{
            margin: 1rem;
        }              
        .pgcontent{
            padding-top: 0;
        }
        
    }
    @media screen and (min-width: 1440px) {
        a>h2{
            padding-left: 4rem;
        }
        .staff-info{
            padding: 1rem calc(var(--padding2) - 2rem);
        }
} 
</style>
<script>
import Intro from '../components/Intro.vue';
import Dentists from "../assets/json/dentists.json";
import StaffCard from '../components/StaffCard.vue';
export default {
name: 'StaffDetail',
components: {
    Intro,
    StaffCard
},
data(){
    return{
        paragraphs: [
            "At Broad Street Dental Practice we have a great team who provide the very best care for our patients.",
            "If you have any questions about your treatment, please don't hesitate to ask any member of our staff who will be only too pleased to help."
        ],
        Dentists
    }
}
}
</script>